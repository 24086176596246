import React from 'react';
import { Link } from 'gatsby';

import './Footer.scss';

function Footer({ links, navigation, tag }) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <Link to={`/${tag}/`}>
            <img
              src="/footer/footer-logo.svg"
              alt="footer-logo"
              className="footer__logo"
            />
          </Link>
          <div className="footer__links">
            <a
              href={links.github}
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer/github.svg" alt="github" />
            </a>
            <a
              href={links.discord}
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer/discord.svg" alt="discord" />
            </a>
            <a
              href={links.twitter}
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer/twitter.svg" alt="twitter" />
            </a>
            <a
              href={links.reddit}
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer/reddit.svg" alt="reddit" />
            </a>
            <a
              href={links.youtube}
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer/youtube.svg" alt="youtube" />
            </a>
          </div>
          <div className="footer__nav">
            <a
              href={links.careers}
              target='_blank'
              rel='noreferrer'
              className="footer__nav-link"
            >
              {navigation.careers}
            </a>
            <Link to={`/${tag}/resources/`} className="footer__nav-link">
              {navigation.resources}
            </Link>
            <Link to={`/${tag}/usecases/`} className="footer__nav-link">
              {navigation.use_cases}
            </Link>
            <Link to={`/${tag}/faq/`} className="footer__nav-link">
              {navigation.faq}
            </Link>
            <Link to={`/${tag}/contact/`} className="footer__nav-link">
              {navigation.contact}
            </Link>
            <Link to={`/${tag}/privacy/`} className="footer__nav-link">
              {navigation.privacy}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default React.memo(Footer);
