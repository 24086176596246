import React, { useState } from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';

import './Nav.scss';

function Nav({ navigation, tag, careersLink, page }) {
  const { allStrapiLanguages } = useStaticQuery(graphql`{
    allStrapiLanguages {
      nodes {
        tag
        flag {
          childImageSharp {
            fluid(maxWidth: 24, maxHeight: 17) {
              src
            }
          }
        }
      }
    }
  }`);

  const [ isOpen, setIsOpen ] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
    document.querySelector('.nav__links').style.paddingRight
      = !isOpen ? '74.4px' : '0';
  };

  const chooseLanguage = (event) => {
    if (event.target.classList.contains('nav__language-item')
      || event.target.closest('.nav__language-item')) {
      if (typeof window !== 'undefined') {
        const url = new URL(window.location);
        const path = url.pathname.slice(4);

        const newLanguage = event.target.closest('.nav__language-item').querySelector('.nav__language-item-image').dataset.lang;

        navigate(`/${newLanguage}/${path}`);
      }
    }
  };

  const defaultLanguage = allStrapiLanguages.nodes
    .find(language => language.tag === tag);

  let images;

  if (typeof window !== 'undefined') {
    images = allStrapiLanguages.nodes
      .map(image => {
        const generatedImage = new Image();
        generatedImage.src = image.flag.childImageSharp.fluid.src;
        generatedImage.alt = 'lang icon';
        generatedImage.className = 'nav__language-item-image';
        generatedImage.dataset.lang = image.tag;
        generatedImage.dataset.isCurrentLang = image.tag === tag;

        return generatedImage;
      })
      .sort((x, y) => y.dataset.isCurrentLang.localeCompare(x.dataset.isCurrentLang));
  }

  return (
    <nav className="nav header__nav">
      <div className="nav__links">
        {/* <Link
          to={`/${tag}/resources/`}
          partiallyActive={true}
          className="nav__link"
          activeClassName="nav__link--active"
        >
          {navigation.resources}
        </Link>
        <Link
          to={`/${tag}/usecases/`}
          partiallyActive={true}
          className="nav__link"
          activeClassName="nav__link--active"
        >
          {navigation.use_cases}
        </Link> */}
        <a
          href="https://docs.reach.sh/"
          className="nav__link"
          target="_blank"
          rel="noreferrer"
        >
          Documentation
        </a>
        <div className="nav__block nav__block--last">
          <span>Community</span>
          <div className="nav__block-content">
            <a
              href="https://events.reach.sh/upcoming#hackathons-widget_16336329851172"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Bounty Hackathons
            </a>
            <a
              href="https://algoscouts.fund/"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Algorand Scout Grant Fund
            </a>
            <a
              href="https://events.reach.sh/upcoming#livestreams-widget_16336329851171"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Live Streams
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLPWjBm58kodP_0oLS1qpJpmfLcANzcu73"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Monthly Updates
            </a>
            <a
              href="https://discord.com/invite/AZsgcXu"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
            <a
              href="https://events.reach.sh/upcoming#virtual-events-widget_16336329851173"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Events
            </a>
            <a
              href="https://youtube.com/playlist?list=PLPWjBm58kodMHVbzwQwnTqLgNPCy5g9Bg"
              className="nav__block-content-item"
              target="_blank"
              rel="noreferrer"
            >
              Featuring Reach
            </a>
          </div>
        </div>
        <Link
          to={`/${tag}/faq/`}
          partiallyActive={true}
          className="nav__link"
          activeClassName="nav__link--active"
        >
          {navigation.faq}
        </Link>
        <a
          href={careersLink}
          className="nav__link"
          target="_blank"
          rel="noreferrer"
        >
          {navigation.careers}
        </a>
        <Link
          to={`/${tag}/contact/`}
          partiallyActive={true}
          className="nav__link"
          activeClassName="nav__link--active"
        >
          {navigation.contact}
        </Link>
      </div>
      {/* <div className="nav__language" onClick={handleOpen}>
        {!isOpen ? (
          <p className="nav__language-default">
            <img
              src={defaultLanguage.flag.childImageSharp.fluid.src}
              alt="lang icon"
              className="nav__language-item-image"
            />
            {defaultLanguage.tag.toUpperCase()}
          </p>
        ) : (
          <ul
            className="nav__language-items"
            onClick={(event) => {
              chooseLanguage(event);
            }}
          >
            {images.map((image, index) => (
              <div className="nav__language-item" key={index}>
                <img
                  src={image.src}
                  alt={image.alt}
                  className={image.className}
                  data-lang={image.dataset.lang}
                />
                <p
                  className="nav__language-item-text"
                  data-lang={image.dataset.lang}
                >
                  {image.dataset.lang.toUpperCase()}
                </p>
              </div>
            ))}
          </ul>
        )}
      </div> */}
    </nav>
  );
}

export default React.memo(Nav);
