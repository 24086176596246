import React, { useState, useEffect } from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { GrFormClose } from 'react-icons/gr';

import Discord from 'images/showcase/discord.svg';
import Github from 'images/showcase/github.svg';
import Reddit from 'images/showcase/reddit.svg';
import Twitter from 'images/showcase/twitter.svg';
import Youtube from 'images/showcase/youtube.svg';

import './HeaderMenu.scss';

function HeaderMenu({ isOpen, toggle, links, tag, navigation }) {
  const { allStrapiLanguages } = useStaticQuery(graphql`{
    allStrapiLanguages {
      nodes {
        tag
        flag {
          childImageSharp {
            fluid(maxWidth: 24, maxHeight: 17) {
              src
            }
          }
        }
      }
    }
  }`);

  const [ open, setOpen ] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const chooseLanguage = (event) => {
    if (event.target.classList.contains('header__menu-language-item')
      || event.target.closest('.header__menu-language-item')) {
      if (typeof window !== 'undefined') {
        const url = new URL(window.location);
        const path = url.pathname.slice(4);

        const newLanguage = event.target.closest('.header__menu-language-item').querySelector('.header__menu-language-image').dataset.lang;

        navigate(`/${newLanguage}/${path}`);
      }
    }
  };

  const defaultLanguage = allStrapiLanguages.nodes
    .find(language => language.tag === tag);

  const unsetScroll = () => {
    document.querySelector('html').style.overflowY = 'unset';
    document.body.style.overflowY = 'unset';
  };

  const handleWindowScroll = () => {
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  let images;

  if (typeof window !== 'undefined') {
    images = allStrapiLanguages.nodes
      .map(image => {
        const generatedImage = new Image();
        generatedImage.src = image.flag.childImageSharp.fluid.src;
        generatedImage.alt = 'lang icon';
        generatedImage.className = 'header__menu-language-image';
        generatedImage.dataset.lang = image.tag;
        generatedImage.dataset.isCurrentLang = image.tag === tag;

        return generatedImage;
      })
      .sort((x, y) => y.dataset.isCurrentLang.localeCompare(x.dataset.isCurrentLang));
  }

  return (
    <div className=
      {isOpen
        ? 'header__menu header__menu--open'
        : 'header__menu'
      }
    >
      <div className="header__menu-top">
        <Link
          to={`/${tag}/`}
          className="header__menu-logo"
          onClick={unsetScroll}
        >
          <img src="/header/mobile-logo.svg" alt="logo" />
        </Link>
        <div className="header__menu-close" onClick={toggle}>
          <GrFormClose />
        </div>
      </div>
      <div className="header__menu-content">
        <div className="header__menu-nav">
          {/* <Link
            to={`/${tag}/resources/`}
            partiallyActive={true}
            className="header__menu-nav-link" activeClassName="header__menu-nav-link--active"
            onClick={unsetScroll}
          >
            {navigation.resources}
          </Link>
          <Link
            to={`/${tag}/usecases/`}
            partiallyActive={true}
            className="header__menu-nav-link" activeClassName="header__menu-nav-link--active"
            onClick={unsetScroll}
          >
            {navigation.use_cases}
          </Link> */}
          <a
            className="header__menu-nav-link"
            href="https://docs.reach.sh/"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
          <div className="header__menu-nav-block">
            <p className="header__menu-nav-block-title">
              Community
            </p>
            <a
              href="https://events.reach.sh/upcoming#hackathons-widget_16336329851172"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Bounty Hackathons
            </a>
            <a
              href="https://algoscouts.fund/"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Algorand Scout Grant Fund
            </a>
            <a
              href="https://events.reach.sh/upcoming#livestreams-widget_16336329851171"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Live Streams
            </a>
            <a
              href="https://www.youtube.com/playlist?list=PLPWjBm58kodP_0oLS1qpJpmfLcANzcu73"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Monthly Updates
            </a>
            <a
              href="https://discord.com/invite/AZsgcXu"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
            <a
              href="https://events.reach.sh/upcoming#virtual-events-widget_16336329851173"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Events
            </a>
            <a
              href="https://youtube.com/playlist?list=PLPWjBm58kodMHVbzwQwnTqLgNPCy5g9Bg"
              className="header__menu-nav-block-link"
              target="_blank"
              rel="noreferrer"
            >
              Featuring Reach
            </a>
          </div>
          <Link
            to={`/${tag}/faq/`}
            partiallyActive={true}
            className="header__menu-nav-link" activeClassName="header__menu-nav-link--active"
            onClick={unsetScroll}
          >
            {navigation.faq}
          </Link>
          <a
            href={links.careers}
            className="header__menu-nav-link"
            target="_blank"
            rel="noreferrer"
          >
            {navigation.careers}
          </a>
          <Link
            to={`/${tag}/contact/`}
            partiallyActive={true}
            className="header__menu-nav-link" activeClassName="header__menu-nav-link--active"
            onClick={unsetScroll}
          >
            {navigation.contact}
          </Link>
          {/* <div className="header__menu-language" onClick={handleOpen}>
            {!open ? (
              <p className="header__menu-language-default">
                <img
                  src={defaultLanguage.flag.childImageSharp.fluid.src}
                  alt="lang icon"
                  className="header__menu-language-image"
                />
                {defaultLanguage.tag.toUpperCase()}
              </p>
            ) : (
              <ul
                className="header__menu-language-items"
                onClick={(event) => {
                chooseLanguage(event);
                }}
              >
                {images.map((image, index) => (
                  <div className="header__menu-language-item" key={index}>
                    <img
                      src={image.src}
                      alt={image.alt}
                      className={image.className}
                      data-lang={image.dataset.lang}
                    />
                    <p
                      className="header__menu-language-text"
                      data-lang={image.dataset.lang}
                    >
                      {image.dataset.lang.toUpperCase()}
                    </p>
                  </div>
                ))}
              </ul>
            )}
          </div> */}
        </div>
        <div className="header__menu-links">
          <a
            href={links.discord}
            className="header__menu-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Discord} alt="discord" />
          </a>
          <a
            href={links.github}
            className="header__menu-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Github} alt="github" />
          </a>
          <a
            href={links.reddit}
            className="header__menu-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Reddit} alt="reddit" />
          </a>
          <a
            href={links.youtube}
            className="header__menu-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Youtube} alt="youtube" />
          </a>
          <a
            href={links.twitter}
            className="header__menu-link"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(HeaderMenu);
