import React, { useEffect } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';

import './Layout.scss';
import { Helmet } from 'react-helmet';

function Layout({
  children,
  isHeaderTransparent,
  withGradient,
  page,
  mainBackground,
  links,
  navigation,
  tag = 'us',
  metaTitle = page,
  metaDescription = ''
 }) {
  const changeBackgroundOnScroll = () => {
    const animatable = document.querySelector('.animatable__blue');
    const lights = document.querySelectorAll('.light');

    if (animatable) {
      const animatableHeight = animatable.offsetHeight;
      const scrollTop = window.pageYOffset;
      const opacity = scrollTop / (animatableHeight - window.innerHeight);

      animatable.style.opacity = (opacity > 1) ? 1 : opacity;
    }

    if (lights) {
      if (window.pageYOffset > 500) {
        for (let light of lights) {
          light.style.animation = 'none';
        }
      } else {
        for (let light of lights) {
          light.style.animation = 'shift calc(var(--duration, 2) * 1.5s) calc(var(--delay, 0) * -1.5s) infinite ease';
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackgroundOnScroll);

    return () => {
      window.removeEventListener('scroll', changeBackgroundOnScroll);
    };
  }, []);

  const mainClass = `main${(page || '') && ' main--' + page}${(mainBackground || '') && ' main--' + mainBackground}`;

  if (withGradient) {
    return (
      <>
        <Helmet>
          <meta />
          <meta name='description' content={metaDescription} />
          <meta property='og:title' content={metaTitle} />
          <meta property='image' content='/seo/preview.jpg' />
          <meta property='og:image' content='/seo/preview.jpg' />
          <meta property='og:image:width' content={820} />
          <meta property='og:image:height' content={540} />
          <title>{metaTitle}</title>
        </Helmet>
        <div className="header-main-wrapper">
          <Header
            isHeaderTransparent={isHeaderTransparent}
            links={links}
            navigation={navigation}
            tag={tag}
            page={page}
          />
          <main className={mainClass}>
            {children}
          </main>
        </div>
        <Footer links={links} navigation={navigation} tag={tag} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name='description' content={metaDescription} />
        <meta property='image' content='/seo/preview.jpg' />
        <meta property='og:image' content='/seo/preview.jpg' />
        <meta property='og:image:width' content={820} />
        <meta property='og:image:height' content={540} />
        <title>{metaTitle}</title>
      </Helmet>
      <Header
        isHeaderTransparent={isHeaderTransparent}
        links={links}
        navigation={navigation}
        tag={tag}
        page={page}
      />
      <main className={mainClass}>
        {children}
      </main>
      <Footer links={links} navigation={navigation} tag={tag} />
    </>
  );
}

export default React.memo(Layout);
