import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { FaBars } from 'react-icons/fa';
import Nav from 'components/Nav';
import HeaderMenu from 'components/HeaderMenu';

import './Header.scss';

function Header({ isHeaderTransparent, links, navigation, tag, page }) {
  const [ isOpen, setOpen ] = useState(false);

  const toggle = () => {
    document.querySelector('html').style.overflowY = isOpen ? 'unset' : 'hidden';
    document.body.style.overflowY = isOpen ? 'unset' : 'hidden';

    setOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      document.querySelector('.header').className = 'header header--scroll';
      document.querySelector('.header__logo img').style.height = '49px';
    } else {
      if (isHeaderTransparent) {
        document.querySelector('.header').className = 'header header--transparent';
      } else {
        document.querySelector('.header').className = 'header';
      }

      document.querySelector('.header__logo img').style.height = '81px';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className=
      {!isHeaderTransparent ? 'header' : 'header header--transparent'}
    >
      <HeaderMenu
        isOpen={isOpen}
        toggle={toggle}
        links={links}
        tag={tag}
        navigation={navigation}
      />
      <div className="container">
        <div className="header__inner">
          <Link to={`/${tag}/`} className="header__logo">
            <img
              src="/header/logo.svg"
              alt="logo"
            />
          </Link>
          <Link to={`/${tag}/`} className="header__mobile-logo">
            <img
              src="/header/mobile-logo.svg"
              alt="logo"
            />
          </Link>
          <Nav
            tag={tag}
            navigation={navigation}
            careersLink={links.careers}
            page={page}
          />
          <div className="header__mobile-button" onClick={toggle}>
            <FaBars />
          </div>
        </div>
      </div>
    </header>
  );
}

export default React.memo(Header);
